import Img from 'gatsby-image';
import React from 'react';
import './featuredSpeaker.scss';

const FeaturedSpeaker = (props) => {
    const getImage = () => {
        if (props.speaker.speakerImage) {
            return (
                <Img
                    className="speaker-image"
                    fluid={props.speaker.speakerImage.asset.fluid}
                />
            );
        }
    };
    return (
        <div className="featured-speaker col-sm-12 col-md-6 row">
            <div className="col-md-12 col-lg-4 speaker-head d-flex justify-content-center justify-content-lg-start align-items-center">
                {getImage()}
            </div>
            <div className="col-md-12 col-lg-7 d-flex flex-column justify-content-center speaker-info">
                <div className="speaker-name">
                    <span className="highlight">
                        {props.speaker.firstName}
                    </span>{' '}
                    {props.speaker.lastName}
                </div>
                <div className="job-title">{props.speaker.jobTitle}</div>
                {/*                <div className="talk-title">
                    {props.speaker.talkTitle}
                </div>*/}
            </div>
        </div>
    );
};

export default FeaturedSpeaker;
