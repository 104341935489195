import React from "react";
import AxxesLogo from "../../images/axxes-logo-black.png";
import EuricomLogo from "../../images/euricom-logo-black.png";
import HCSLogo from "../../images/hcscompany-logo-black.png";
import './organisedBy.scss';

const OrganisedBy = () => {
    
    return (
        <div id="organised-by" className="pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 my-4">
                        <h3>Organised by<span className="highlight">:</span></h3>
                    </div>
                </div>
                <div className="row align-items-end">
                    <div className="col-sm-12 col-md my-5 d-flex justify-content-center justify-content-md-start">
                        <a href="https://axxes.com/" className="d-flex justify-content-center justify-content-sm-start" target="_blank" alt="website Axxes" rel="noopener">
                            <img className="w-75" id="organised-axxes-logo" alt="axxes-logo" src={AxxesLogo} />
                        </a>
                    </div>
                    <div className="col-sm-12 col-md my-5 d-flex justify-content-center justify-content-md-start">
                        <a href="https://www.euri.com/" className="d-flex justify-content-center justify-content-sm-start" target="_blank" alt="website Euricom" rel="noopener">
                            <img className="w-75" id="organised-euricom-logo" alt="euricom-logo" src={EuricomLogo} />
                        </a>
                    </div>
                    <div className="col-sm-12 col-md my-5 d-flex justify-content-center justify-content-md-start">
                        <a href="https://www.hcs-company.com/" className="d-flex justify-content-center justify-content-sm-start" target="_blank" alt="website HCS Company" rel="noopener">
                            <img className="w-75" id="organised-hcs-company-logo" alt="hcs-company-logo" src={HCSLogo} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganisedBy;
