import React from "react";

const Practical = () => {

    return (
        <div id="practical" className="pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 my-4">
                        <h3>Practical information<span className="highlight">:</span></h3>
                    </div>
                </div>
                <div className="row align-items-end">
                    <div className="col-sm-12 d-flex justify-content-center justify-content-md-start">
                        Date: Thursday, 6 October 2022
                    </div>
                    <div className="col-sm-12 d-flex justify-content-center justify-content-md-start">
                        Timing: 9am – 8pm
                    </div>
                    <div className="col-sm-12 d-flex justify-content-center justify-content-md-start">
                        Location: Van der Valk Hotel, Gentseweg 280, 9120 Beveren, België
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Practical;
