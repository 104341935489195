import React from "react";
import "./aboutTreeNation.scss";
import TreenationLogo from '../../images/tree-nation-logo-circle.svg';

const AboutTreeNation = () => {
    return (
        <div id="about-tree-nation" className="pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 my-4">
                        <h3>About Tree-Nation<span className="highlight">:</span></h3>
                    </div>
                </div>
                <div className="row mt-5 align-items-center">
                    <div
                        className="col-lg-4 col-md-5 col-sm-12 mb-5 mb-sm-0 d-flex d-sm-block justify-content-center justify-content-sm-start">
                        <div id="tree-nation-logo-wrapper" className="d-flex justify-content-center align-items-center">
                            <a href="https://www.tree-nation.com/" alt="website Treenation" target="_blank" rel="noopener">
                                <img alt="tree-nation-logo" id="tree-nation-logo" src={TreenationLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col">
                        <p>
                            A citizen solution to climate change.<br/>
                            Tree-Nation gathers and coordinates reforestation efforts worldwide in a unique platform,
                            enabling every citizen, company and planter to take action.
                        </p>
                        <p>They are already involved:</p>
                        <ol>
                            <li>91 Planting Projects</li>
                            <li>386 387 Citizens</li>
                            <li>9 068 Companies</li>
                        </ol>

                        <a href="https://www.tree-nation.com" target="_blank">Tree-Nation</a>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AboutTreeNation;
