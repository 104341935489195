import React, { useEffect, useState } from 'react';
import './speakerCountDownTimer.scss';

const SpeakerCountDownTimer = ({ now, releaseDateLocalTimeZone }) => {
    const getDifference = () => {
        let difference = releaseDateLocalTimeZone - now;
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [difference, setDifference] = useState({});

    useEffect(() => {
        setDifference(getDifference());
    }, [now]);

    return (
        <div id="speaker-count-down" className="dark-bg">
            <div className="container h-100">
                <div className="d-flex align-items-center h-100 hide">
                    <h3>
                        Featured speakers announced in
                        <span className="highlight">:</span>
                    </h3>
                    <div className="d-none d-sm-block">
                        <h1>
                            <span className="highlight">
                                {difference?.days}
                            </span>{' '}
                            days,{' '}
                            <span className="highlight">
                                {difference?.hours}
                            </span>{' '}
                            hr,{' '}
                            <span className="highlight">
                                {difference?.minutes}
                            </span>{' '}
                            min,{' '}
                            <span className="highlight">
                                {difference?.seconds}
                            </span>{' '}
                            sec
                        </h1>
                    </div>
                    <div className="d-block d-sm-none mt-5 mt-sm-0">
                        <h1>
                            <span className="highlight">
                                {difference?.days}
                            </span>{' '}
                            days
                        </h1>
                        <h1>
                            <span className="highlight">
                                {difference?.hours}
                            </span>{' '}
                            hr
                        </h1>
                        <h1>
                            <span className="highlight">
                                {difference?.minutes}
                            </span>{' '}
                            min
                        </h1>
                        <h1>
                            <span className="highlight">
                                {difference?.seconds}
                            </span>{' '}
                            sec
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpeakerCountDownTimer;
