import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import './featuredSpeakers.scss';
import FeaturedSpeaker from "./FeaturedSpeaker";
import {Link} from "gatsby";

const FeaturedSpeakers = () => {
    const speakerData = useStaticQuery(graphql`
        query speakerDataQuery {
            sanitySpeakers {
                speakers {
                  isFeaturedOnHomePage
                  firstName
                  lastName
                  jobTitle
                  talkTitle
                  bio {
                    children {
                      text
                    }
                  }
                  speakerImage {
                    asset {
                        fluid(maxWidth: 200) {
                            ...GatsbySanityImageFluid
                        }
                    }
                  }
                }
            }
        }
    `);

    const getFeaturedSpeakers = () => {
        return speakerData.sanitySpeakers.speakers
            .filter(x => x.isFeaturedOnHomePage === true)
            .map((speaker, key) => {
                return (<FeaturedSpeaker key={key} speaker={speaker}/>)
            })
    }

    return (
      <div id="featured-speakers" className="pb-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 my-4">
              <h3>
                Featured speakers<span className="highlight">:</span>
              </h3>
            </div>
          </div>
          <div className="row mt-5">{getFeaturedSpeakers()}</div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center my-5">
              <Link to="schedule">
                <button className="btn btn-primary btn-lg">Schedule</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
};

export default FeaturedSpeakers;