import React, { useEffect, useState } from 'react';
import AboutTreeNation from '../components/aboutTreeNation/AboutTreeNation';
import { DangerBar } from '../components/dangerBar/DangerBar';
import DonationAmount from '../components/donationAmount/DonationAmount';
import DonorLogos from '../components/donorLogos/DonorLogos';
import { DoorsOpenBar } from '../components/doorsOpenBar/DoorsOpenBar';
import FeaturedSpeakers from '../components/featuredSpeakers/FeaturedSpeakers';
import HomeFold from '../components/homeFold/HomeFold';
import Layout from '../components/layout/Layout';
import NavBar from '../components/navbar/NavBar';
import OrganisedBy from '../components/organisedBy/OrganisedBy';
import SEO from '../components/seo/Seo';
import SpeakerCountDownTimer from '../components/speakerCountDown/SpeakerCountDownTimer';
import Practical from "../components/practical/Practical";

const IndexPage = () => {
    const getNowUtc = () => Date.parse(new Date().toUTCString().substr(0, 25));
    const [now, setNow] = useState(getNowUtc());
    const [releaseDateLocalTimeZone, setReleaseDateLocalTimeZone] = useState(
        Date.UTC(2021, 0, 29, 8, 0, 0, 0)
    );
    const [isTimerStarted, setIsTimerStarted] = useState(false);

    useEffect(() => {
        now <= releaseDateLocalTimeZone && startTimer();
    }, [now, releaseDateLocalTimeZone]);

    const startTimer = () => {
        if (isTimerStarted === true) return;

        setIsTimerStarted(true);
        setInterval(() => {
            setNow(getNowUtc());
        }, 1000);
    };

    return (
        <Layout>
            <SEO title="Home" />
            <NavBar />
            {/* <DangerBar /> */}
            {/* <DoorsOpenBar /> */}
            <HomeFold />
            {/*<DonationAmount />*/}
            {now <= releaseDateLocalTimeZone ? (
                <SpeakerCountDownTimer
                    now={now}
                    releaseDateLocalTimeZone={releaseDateLocalTimeZone}
                />
            ) : (
                <FeaturedSpeakers />
            )}
            <Practical />
            <AboutTreeNation />
            <DonorLogos />
            <OrganisedBy /> 
        </Layout>
    );
};

export default IndexPage;
