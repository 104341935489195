import React, {useEffect} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import "./donorLogos.scss";

const DonorLogos = () => {
    const donorData = useStaticQuery(graphql`
    query donorDataQuery {
        sanityDonors {
            donors {
              name,
              imageUrl,
              Tier
              donorImage {
                asset {
                    fluid(maxWidth: 200) {
                        ...GatsbySanityImageFluid
                    }
                }
              }
            }
        }
    }
`);

    return (
        <>
        <div id="donor-logos">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 mt-4">
                        <h3>Donors<span className="highlight">:</span></h3>
                    </div>
                </div>
                {donorData.sanityDonors.donors.filter(x => x.Tier === "Platinum").length > 0 && <>
                    <div className="row d-flex justify-content-around mt-5 logos">
                        {donorData.sanityDonors.donors.filter(x => x.Tier == "Platinum").map((donor, key) => <DonorLogo key={key} colLg="6" colMd="6" name={donor.name} image={donor.donorImage} imageUrl={donor.imageUrl} tier={donor.Tier}/>)}
                    </div>
                </>}

                {donorData.sanityDonors.donors.filter(x => x.Tier === "Gold").length > 0 && <>
                    <div className="row d-flex justify-content-around mt-5 logos">
                        {donorData.sanityDonors.donors.filter(x => x.Tier == "Gold").map((donor, key) => <DonorLogo key={key} colMd="4" colLg="4" name={donor.name} image={donor.donorImage} imageUrl={donor.imageUrl} tier={donor.Tier}/>)}
                    </div>
                </>}

                {donorData.sanityDonors.donors.filter(x => x.Tier === "Silver").length > 0 && <>
                    <div className="row d-flex justify-content-around mt-5 logos">
                        {donorData.sanityDonors.donors.filter(x => x.Tier == "Silver").map((donor, key) => <DonorLogo key={key} colMd="3" colLg="3" name={donor.name} image={donor.donorImage} imageUrl={donor.imageUrl} tier={donor.Tier}/>)}
                    </div>
                </>}

                {donorData.sanityDonors.donors.filter(x => x.Tier === "Bronze").length > 0 && <>
                    <div className="row d-flex justify-content-around mt-5 logos">
                        {donorData.sanityDonors.donors.filter(x => x.Tier == "Bronze").map((donor, key) => <DonorLogo key={key} colMd="2" colLg="2" name={donor.name} image={donor.donorImage} imageUrl={donor.imageUrl} tier={donor.Tier}/>)}
                    </div>
                </>}
            </div>
        </div>
        </>
    )
};

const DonorLogo = ({image, imageUrl, name, tier, colMd, colLg}) => {
    return (
      <>
        {image?.asset?.fluid !== undefined && (
          <>
            <div className={"col-sm-12 d-flex justify-content-center " + " col-md-" + colMd + " col-lg-" + colLg}>
              <div className="d-flex flex-column align-items-center justify-content-end">
                <a href={imageUrl} rel="noopener" target="_blank">
                    <Img title={name} alt={name} className={"donor-image " + tier} fluid={image.asset.fluid} />
                </a>
              </div>
            </div>
          </>
        )}
      </>
    );
}

export default DonorLogos;