import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import Arrow from '../../images/arrow.svg';
import Logo from '../../images/logo_22.svg';
import SharpspringSubscribe from '../sharpspringSubscribe/SharpspringSubscribe';
import './homefold.scss';

const HomeFold = () => {
    const data = useStaticQuery(graphql`
        query homeFoldPQuery {
            file(relativePath: { eq: "background_22_final.png" }) {
                childImageSharp {
                    fluid(quality: 70, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <BackgroundImage
            Tag="div"
            className="home-fold align-items-center"
            backgroundColor="white"
            style={{
                backgroundPosition: `top`,
            }}
            fluid={data.file.childImageSharp.fluid}
        >
            <div id="home-fold-overlay">
                <div className="home-fold-inner">
                    <div>
                        <img
                            className="header-logo desktop"
                            src={Logo}
                            alt="Refactor"
                        />
                    </div>
                    <div className="content">
                        <div className="date">6th of October</div>
                        <div className="title">
                            SOFTWARE DEVELOPMENT{' '}
                            <span className="highlight_bold">CONFERENCE</span>
                        </div>
                        <div className="sub-title">
                            FOR TREE-NATION
                        </div>
                        <div className="info">
                            <div className="info-line">
                                Join us in person for a new take on on IT conferences:
                            </div>

                            <div className="info-line">
                                <img alt="arrow" src={Arrow} />
                                <div>
                                    Insights & inspiration in software development, software delivery and current IT trends
                                </div>
                            </div>

                            <div className="info-line">
                                <img alt="arrow" src={Arrow} />
                                <div>Watch the talks in our full experience venue and breakout rooms</div>
                            </div>

                            <div className="info-line">
                                <img alt="arrow" src={Arrow} />
                                <div>Meet the speakers and other attendees over food and drinks during the breaks</div>
                            </div>

                            <div className="info-line">
                                <img alt="arrow" src={Arrow} />
                                <div>
                                    Contribute to a healthier planet through our charity, Tree-Nation
                                </div>
                            </div>
                            <div className="info-line">
                                <img alt="arrow" src={Arrow} />
                                <div>
                                    End the day with a bang with a fantastic after-party!
                                </div>
                            </div>
                        </div>
                        <div className="row extra-margin mb-5">
                            <SharpspringSubscribe
                                classNames="col-sm-12"
                                resultClassNames="padding-left"
                            />
                        </div>
                    </div>
                </div>
                {/*            <div
                    id="scroll-icon"
                    className="d-flex w-100 justify-content-center mb-5"
                >
                    <img alt="scroll-icon" src={ScrollIcon} />
                </div>*/}
            </div>
        </BackgroundImage>
    );
};

export default HomeFold;
